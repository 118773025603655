import { useState, useEffect } from "react";
import cx from "classnames";
import { Link } from "react-router-dom";

import ExchangeStatus from "./ExchangeStatus/exchangeStatus.component.jsx";
import RebalanceQuadratic from "./RebalanceQuadratic/index.jsx";
import AssetCategory from "./AssetCategory/index.jsx";
import RFQBaseV2 from "./RFQBaseV2/index.jsx";
import SetTarget from "./SetTarget";
import TokenPairListing from "./TokenPairListing/tokenPairListing.component.jsx";
import TradingPairs from "./TradingPairs";
import FutureTradingPairs from "./FutureTradingPairs";
import RFQParams from "./RFQParams";
import ManualAsset from "./ManualAsset";
import RFQBase from "./RFQBase/index.js";
import Integrations from "./Integrations";
import AlertSettings from "./AlertSettings";
import { Switch } from "../Share";
import useLocalStorage from "hook/useLocalStorage";
import useScreenSize from "hook/useScreenSize.js";
import { useDispatch, useSelector } from "react-redux";
import { getAllTokens } from "services/configuration";
import { saveTokens } from "actions/globalActions";

import "./configuration.scss";
import "jsoneditor-react/es/editor.min.css";

const allTabConfigs = [
    {
        id: "set_target",
        linkTo: "/configuration/set_target",
        title: "Set Target",
        component: SetTarget,
        showHideAsset: true,
    },
    {
        id: "exchange_status",
        linkTo: "/configuration/exchange_status",
        title: "Exchange Status",
        component: ExchangeStatus,
    },
    {
        id: "alert_settings",
        linkTo: "/configuration/alert_settings",
        title: "Alert Settings",
        component: AlertSettings,
    },
    {
        id: "asset_category",
        linkTo: "/configuration/asset_category",
        title: "Asset Categories",
        component: AssetCategory,
        minDisplay: 1200,
    },
    {
        id: "rfq_base_v2",
        linkTo: "/configuration/rfq_base_v2",
        title: "RFQ Bases",
        component: RFQBaseV2,
        showHideAsset: true,
        minDisplay: 1300,
    },
    {
        id: "rfq_pair_params",
        linkTo: "/configuration/rfq_pair_params",
        title: "RFQ Pairs",
        component: RFQParams,
        showHideAsset: true,
        minDisplay: 1400,
    },
    {
        id: "token_pair_listing",
        linkTo: "/configuration/token_pair_listing",
        title: "Token Pairs Listing",
        component: TokenPairListing,
        showHideAsset: true,
        minDisplay: 1500,
    },
    {
        id: "manual_asset",
        linkTo: "/configuration/manual_asset",
        title: "Manual Assets",
        component: ManualAsset,
        minDisplay: 1650,
    },
    {
        id: "rebalance_quadratic",
        linkTo: "/configuration/rebalance_quadratic",
        title: "Rebalance Quadratic",
        component: RebalanceQuadratic,
        showHideAsset: true,
        minDisplay: 1850,
    },
    {
        id: "trading_pairs",
        linkTo: "/configuration/trading_pairs",
        title: "Trading Pairs",
        component: TradingPairs,
        showHideAsset: true,
        minDisplay: 2050,
    },
    {
        id: "future_trading_pairs",
        linkTo: "/configuration/future_trading_pairs",
        title: "Future Trading Pairs",
        component: FutureTradingPairs,
        showHideAsset: true,
        minDisplay: 2250,
    },
    {
        id: "rfq_base",
        linkTo: "/configuration/rfq_base",
        title: "RFQ Bases V1",
        component: RFQBase,
        showHideAsset: true,
        minDisplay: 2250,
    },

    {
        id: "integrations",
        linkTo: "/configuration/integrations",
        title: "Integrations",
        component: Integrations,
        minDisplay: 2250,
    },
];

const tabConfigsByScreenWidth = (width) => {
    if (width <= 992 || width > 2250) {
        return allTabConfigs;
    }

    const tabs = allTabConfigs.filter((tab) => !tab.minDisplay || width > tab.minDisplay);
    const children = allTabConfigs.filter((tab) => tab.minDisplay && width <= tab.minDisplay);

    return [
        ...tabs,
        {
            title: "More Settings",
            children,
        },
    ];
};

const Configuration = ({ match, history }) => {
    const apiService = useSelector((state) => state.global.apiService);
    const dispatch = useDispatch();

    const screenSize = useScreenSize();
    const [tabConfigs, setTabConfigs] = useState(tabConfigsByScreenWidth(window.innerWidth));
    const [config, setConfig] = useState(tabConfigs[0]);
    const Component = config.component;
    const [isShowAllAssets, setShowAllAssets] = useLocalStorage("configuration-show-all-assets", false);

    useEffect(() => {
        if (!match?.url) {
            history.replace(tabConfigs[0].linkTo);
            return;
        }

        tabConfigs.forEach((cfg) => {
            if (cfg.children) {
                cfg.children.forEach((ch) => {
                    if (ch.linkTo == match.url) {
                        setConfig(ch);
                        return;
                    }
                });
            } else {
                if (cfg.linkTo == match.url) {
                    setConfig(cfg);
                    return;
                }
            }
        });
    }, [history, match.url]);

    useEffect(() => {
        const cook = async () => {
            const res = await getAllTokens(apiService);
            dispatch(saveTokens(res.data));
        };
        cook();
    }, [isShowAllAssets]);

    useEffect(() => {
        setTabConfigs(tabConfigsByScreenWidth(screenSize.width));
    }, [screenSize.width]);

    const ShowHide = () =>
        config.showHideAsset ? (
            <div className="ml-3 pointer ml-mb-0 my-mb-3 w-sm-100">
                <Switch onChange={(checked) => setShowAllAssets(checked)} checked={isShowAllAssets} />
                <span className="ml-2 text-muted">{isShowAllAssets ? "Show disabled" : "Hide disabled"} assets</span>
            </div>
        ) : (
            <div />
        );

    return (
        <section className="container-fluid">
            <nav className="navbar navbar-expand-lg navbar-light px-0 py-4 nav-sub">
                <h4 className="page-tile">Configuration</h4>

                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarText"
                    aria-controls="navbarText"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon" />
                </button>

                <ShowHide />

                <div className="collapse navbar-collapse mr-4" id="navbarText">
                    <ul className="navbar-nav ml-auto">
                        {tabConfigs.map((cfg) => {
                            const isActive = config.id == cfg.id || cfg.children?.map((c) => c.id).includes(config.id);

                            return cfg.children?.length ? (
                                <li className={cx("nav-item dropdown", isActive && "active")} key={cfg.title}>
                                    <a
                                        className="nav-link dropdown-toggle"
                                        type="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="true"
                                    >
                                        {cfg.title}
                                    </a>
                                    <ul className="dropdown-menu">
                                        {cfg.children.map((child) => (
                                            <li
                                                className={"nav-item dropdown-item p-0"}
                                                key={child.title}
                                                style={{ fontSize: "13.5px" }}
                                            >
                                                <Link className="nav-link dropdown-nav-item" to={child.linkTo}>
                                                    {child.title}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ) : (
                                <li key={cfg.id} className={cx("nav-item", isActive && "active")}>
                                    <Link className="nav-link" to={cfg.linkTo}>
                                        {cfg.title}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </nav>

            <Component isShowAllAssets={isShowAllAssets} />
        </section>
    );
};

export default Configuration;
